import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'
import Layout from '../components/Layout'

const AboutPage = ({ data }) => (
    <Layout>
        <div className="About">
            <GatsbyImage className="About__image" image={getImage(data.page.image.localFile.childImageSharp.gatsbyImageData)} />
            <p className="About__text">{data.page.text.text}</p>
        </div>
    </Layout>
)

export const query = graphql`
    {
        page: contentfulPage(title: { eq: "About" }) {
            title
            text {
                id
                text
            }
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            placeholder: TRACED_SVG
                            formats: [JPG, WEBP, AVIF]
                            outputPixelDensities: [0.1, 0.25, 0.5, 1, 2]
                            width: 1190
                            transformOptions: { fit: COVER, cropFocus: CENTER }
                            jpgOptions: { quality: 60 }
                            avifOptions: { quality: 50 }
                            webpOptions: { quality: 64 }
                        )
                    }
                }
            }
        }
    }
`

export default AboutPage
